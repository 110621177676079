import useStateRef from "@utils/use-state-ref";
import { type Dispatch, type SetStateAction, useCallback, useEffect } from "react";
import { useCookies } from "react-cookie";

const USER_ENV_COOKIE_NAME = "publication-env";

type PublicationEnv = "staging" | "production";

type PublicationEnvSetValue = Dispatch<SetStateAction<PublicationEnv | undefined>>;

export const usePublicationEnv = (): [PublicationEnv | undefined, PublicationEnvSetValue] => {
  const [cookies, setCookie] = useCookies([USER_ENV_COOKIE_NAME]);
  // in combination with the useEffect avoids server/client state mismatch
  const [publicationEnvState, setPublicationEnvState, publicationEnvStateRef] = useStateRef<
    PublicationEnv | undefined
  >(undefined);

  const setPublicationEnv: PublicationEnvSetValue = useCallback(
    (value) => {
      const newValue = value instanceof Function ? value(publicationEnvStateRef.current) : value;
      // expires in 1 year
      setCookie(USER_ENV_COOKIE_NAME, newValue, { maxAge: 31556952 });
      window.location.href = "/";
    },
    [setCookie, publicationEnvStateRef],
  );

  useEffect(() => {
    // new users without this cookie are in production
    setPublicationEnvState(cookies[USER_ENV_COOKIE_NAME] || "production");
  }, [cookies, setPublicationEnvState]);

  return [publicationEnvState, setPublicationEnv];
};
